import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

const Screen = lazy(() => import('../screens/ScreenEditor/index'));
const AdminScreens = lazy(() => import('../screens/AdminScreensEditor'));
const Dashboard = lazy(() => import('../screens/Dashboard'));
const AccountDeletion = lazy(() => import('../screens/Account/Deletion'));
const AccountResetPassword = lazy(() => import('../screens/Account/ResetPassword'));
const AccountRestorePassword = lazy(() => import('../screens/Account/RestoreAccount'));
const PromoCodeGenerateScreen = lazy(() => import('../screens/PromoCodeGenerate'));
const CuratedMix = lazy(() => import('../screens/CuratedMix'));

export const routeDashboard: () => string = () => '/';
export const routeScreen: (adminScreenID: string) => string = (adminScreenID: string) => `/screen/${adminScreenID}`;
export const routeAdminVersionScreen: ( adminScreenID: string, adminScreenVersionId:string ) => string = (adminScreenID: string, adminScreenVersionId:string) => `/screen/${adminScreenID}/version/${adminScreenVersionId}`;

// Note in the future maybe this could be other types of screens so only include :id?
export const routeScreens: () => string = () => '/screen/:type/version/:id';
export const routeScreenType: () => string = () => '/screen/:type';
export const routeAccountDeletion: () => string = () => '/account/deletion';

export const routeAccountResetPassword: () => string = () => '/account/resetPassword';
export const routeAccountRestoreUser: () => string = () => '/account/restoreAccount';
export const routePromoCodeGenerate: () => string = () => '/promoCodeGenerate';

export const routeManageCuratedMixes: () => string = () => '/curatedMix';

export const PrivateScreenRoutes: RouteProps[] = [
  {
    path: routeDashboard(),
    component: Dashboard,
    exact: true
  },
  {
    path: routeScreens(),
    component: Screen,
    exact: true
  },
  {
    path: routeScreenType(),
    component: AdminScreens,
    exact: true
  },
  {
    path: routeAccountDeletion(),
    component: AccountDeletion,
    exact: true
  },
  {
    path: routeAccountResetPassword(),
    component: AccountResetPassword,
    exact: true
  },
  {
    path: routeAccountRestoreUser(),
    component: AccountRestorePassword,
    exact: true
  },
  {
    path: routePromoCodeGenerate(),
    component: PromoCodeGenerateScreen,
    exact: true
  },
  {
    path: routeManageCuratedMixes(),
    component: CuratedMix,
    exact: true
  },
];

export const PublicScreenRoutes: RouteProps[] = [];
